import {NavLink, useHistory} from "react-router-dom";

// images
import {ReactComponent as Logo} from "../../img/logo-white.svg";
import {ReactComponent as IconLogout} from "../../img/icon/logout.svg";
import {ReactComponent as IconUser} from "../../img/icon/user.svg";
import {ReactComponent as IconBurgerMenu} from "../../img/icon/burger-menu.svg";

// scss
import "../../css/_root.scss";

// components
import DarkThemeToggle from "../DarkThemeToggle";
import {Message} from "../Message";
import {logout} from "../../js/main";
import {useRef} from "react";


export const Layout = ({children, mainNav, username}) => {

    const history = useHistory();

    const sidebarDom = useRef();

    const performLogout = () => {
        logout().then(() => {
            history.push("/");
        })
    }

    return (
        <div id="outer-wrapper">
            <Message/>

            <div id="sidebar" ref={sidebarDom} className="center">
                <NavLink to="/">
                    <Logo className="logo" title="Key Direct – Schlüsselverwaltung 2.0"/>
                </NavLink>

                <nav className="main-nav" role="navigation">
                    {mainNav}
                </nav>
            </div>

            <div id="main">
                <div id="topbar">
                    {mainNav && <div className="burger-menu only-tablet" onClick={() => sidebarDom.current.classList.toggle("menu-visible")}>
                        <IconBurgerMenu/>
                    </div>}

                    {!mainNav && <NavLink className="logo-wrapper only-tablet" to="/">
                        <Logo className="logo" title="Key Direct – Schlüsselverwaltung 2.0"/>
                    </NavLink>}

                    {username !== null &&
                    <NavLink to="/profil" className="benutzer" activeClassName="current">
                        <IconUser/> <span className="ml-05">{username}</span>
                    </NavLink>}

                    {username !== null &&
                    <div className="logout">
                        <button slot="sign-out" type="button" onClick={() => performLogout()}>
                            <IconLogout/>
                        </button>
                    </div>}
                </div>
                <div id="content">
                    {children}
                </div>
                <div id="footer">
                    <NavLink to="/agb" activeClassName="current">AGB</NavLink>
                    <NavLink to="/datenschutz" activeClassName="current">Datenschutz</NavLink>
                    <NavLink to="/impressum" activeClassName="current">Impressum</NavLink>
                    <NavLink to="/widerrufsrecht" activeClassName="current">Widerrufsrecht</NavLink>
                </div>
            </div>
        </div>
    );

};
