import {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {BreadCrumb} from "../../component/Breadcrumb";
import {InputText} from "../../component/form/InputText";
import {handleApiError, post} from "../../js/api";
import {markDirty, showError, showMessage} from "../../js/main";
import {OrderInformation} from "../../component/OrderInformation";
import {Button} from "../../component/form/Button";
import {getOwnUser} from "../../controller/Profile";


export const UpdateOrder = ({orders}) => {

    const {orderId} = useParams();

    const [selectedOrder, setSelectedOrder] = useState();

    const [signatureCode, setSignatureCode] = useState();

    const [submitting, setSubmitting] = useState(false);
    const [canCancel, setCanCancel] = useState(true);
    const [canSign, setCanSign] = useState(true);

    useEffect(() => {
        if (orders) {
            const tmp = orders.find(o => o.id === parseInt(orderId));

            getOwnUser().then((user) => {
                if(tmp.username && tmp.username.toLowerCase() == user.username.toLowerCase()){
                    setCanSign(true);
                    setCanCancel(["CREATED", "AWAIT_PAYMENT"].indexOf(tmp.state) != -1);
                }else{
                    setCanSign(false);
                    setCanCancel(false);
                }
                setSelectedOrder(orders.find(o => o.id === parseInt(orderId)));
            })

        }
    }, [orders]); // eslint-disable-line react-hooks/exhaustive-deps

    const history = useHistory();

    const signOrder = () => {
        setSubmitting(true);

        const req = {
            tan: signatureCode
        };

        post("/orders/" + orderId + "/signOrder", req, false).then((resp) => {
            markDirty();
            showMessage("Die Bestellung wurde erfolgreich bestätigt.");
            history.push("/meine-bestellungen");
        }).catch(err => {
            setSignatureCode("");
            let msg = "Der eingegebene Code war nicht korrekt.";
            if (err.response.data) {
                switch (err.response.data.errorType) {
                    case "RETRY":
                        msg += "Bitte versuchen Sie es erneut.";
                        break;
                    case "FAIL":
                        msg += "Die Bestellung wurde storniert.";
                        history.push("/meine-bestellungen");
                        break;
                    default:
                        break;
                }
                showError(msg);
                return;
            }
            handleApiError(err);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const requestNewTan = () => {
        setSubmitting(true);
        post("/orders/" + orderId + "/requestNewTan").then(() => {
            showMessage("Neuer Code wurde erfolgreich versendet.");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const cancelOrder = () => {
        setSubmitting(true);
        post("/orders/" + orderId + "/cancelOrder").then(() => {
            markDirty();
            showMessage("Die Bestellung wurde erfolgreich storniert.");
            history.push("/bestellungen");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Fragment>
            {selectedOrder &&
            <div>
                <BreadCrumb currentTitle={"Bestellung " + orderId} parentTitle="Meine Bestellungen" parentLink="/meine-bestellungen"/>

                {selectedOrder.state === "CREATED" &&
                <div className="zeichnung mt-5 mb-5">
                    <h2 className="primary-link mt-0">Bestellung autorisieren</h2>
                    {canSign && <>
                        <p>
                        <strong>Sie haben den Bestätigungscode per Mail erhalten. Überprüfen Sie Ihren
                            Spam-Ordner!</strong> Mit Eingabe des
                        Codes wird die Bestellung abgeschickt, Sie stimmen damit unseren AGB zu und es wird ein
                        rechtsgültiges Geschäft
                        abgeschlossen. Der Code besteht nur aus Zahlen.
                        </p>
                        <form onSubmit={event => {
                            event.preventDefault();
                            signOrder();
                        }}>
                            <InputText required label="Code" name="tan" value={signatureCode}
                                       setter={newValue => setSignatureCode(newValue)}/>
                            <Button submitting={submitting} width={220} theme="green mr-1">Bestellung
                                bestätigen</Button>
                            <Button submitting={submitting} width={230} type="button" onClick={() => requestNewTan()}
                                    theme="orange">Neuen Code anfordern</Button>
                        </form>
                    </>}
                    {!canSign && <>
                        <p>
                            <strong>Die Bestellung kann nur vom Ersteller bestätigt werden.</strong>
                        </p>
                    </>}

                </div>}

                <OrderInformation order={selectedOrder} showUser/>

                {canCancel && <form onSubmit={e => {
                    e.preventDefault();
                }} className="storno mt-3 ">
                    <h3 className="red mt-0">Bestellung stornieren</h3>
                    <p>
                        Wenn Sie die Bestellung stornieren, kann das nicht mehr widerrufen werden. Wenn Sie die
                        Bestellung später dennoch
                        durchführen wollen, muss eine neue Bestellung erstellt werden. Nach der Zeichnung kann die
                        Bestellung nur mehr von
                        Mitarbeitern der Lintner GmbH storniert werden. Setzen Sie sich hierfür bei Bedarf mit dem
                        Kundenservice in
                        Kontakt.
                    </p>
                    <Button submitting={submitting} width={230} theme="red" onClick={() => cancelOrder()}>Bestellung
                        stornieren</Button>
                </form>}

                <br/><br/>
            </div>}
        </Fragment>
    );

};