import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {ReactComponent as BackButton} from "../img/icon/back-arrow.svg";
import {setSiteTitle} from "../js/main";
import {useHistory} from "react-router";

export const BreadCrumb = ({currentTitle, parentTitle}) => {

    const history = useHistory();

    useEffect(() => {
        setSiteTitle(currentTitle);
    }, [currentTitle]);

    return (
        <h1>
            {parentTitle &&
             <span className="breadcrumb">
                <a onClick={e => history.goBack()} className="def">
                    <BackButton className="def"/> {parentTitle}
                </a> /&nbsp;
            </span>}
            {currentTitle}
        </h1>
    );

};