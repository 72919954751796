import {getOrderStateTag} from "../view/myOrders/Overview";
import {Link} from "react-router-dom";
import {OrderItems} from "./OrderItems";
import {OrderAddressDisplay} from "../view/myOrders/OrderAddressDisplay";
import {germanDateTime} from "../js/util";
import {OrderShippingAndTotal} from "./OrderShippingAndTotal";
import {orderType, paymentOptions} from "../js/constants";

export const OrderInformation = ({order, referencingOrderInfo, showUser = false, canJumpToUser = false}) => {

    const getWording = ()=>{
        return order.type == orderType.request ? "Anfrage" : "Bestell";
    }
    const getDescription = (paymentType) => {
        return paymentOptions.find(p => p.value == paymentType)?.label ?? '';
    }

    const getReferencingDescription = (referencingOrderInfo) => {
        if(referencingOrderInfo.type == "AUTH"){
            return "Genehmigt durch";
        }else if(referencingOrderInfo.type =="CUSTOMER"){
            return "QR-Bestellung";
        }
    }

    return (
        <>
            <h2>{getWording()}informationen</h2>
            <table className="mb-1">
                <tbody>
                    <tr>
                        <td width="200"><strong>Status</strong></td>
                        <td><strong>{getOrderStateTag(order.state)}</strong></td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>{getWording()}nummer</td>
                        <td>{order.id}</td>
                    </tr>
                    <tr>
                        <td>Datum</td>
                        <td>{germanDateTime(order.creationDate)}</td>
                    </tr>
                    <tr>
                        <td>Beschreibung</td>
                        <td>{order.summary}</td>
                    </tr>
                    <tr>
                        <td>E-Mail</td>
                        <td>{order.email}</td>
                    </tr>
                    {showUser && order.username && <tr>
                        <td>Bestellt von</td>
                        <td>
                            {canJumpToUser && <Link to={"/benutzer/edit/" + order.username}>{order.username}</Link>}
                            {!canJumpToUser && <>{order.username}</>}
                        </td>
                    </tr>}
                    {order.type != orderType.request &&
                        <tr>
                            <td>Bezahlart</td>
                            <td>{getDescription(order.paymentType)}</td>
                        </tr>
                    }
                    {order.internalOrderId &&
                        <tr>
                            <td>Interne Bestellnummer</td>
                            <td>{order.internalOrderId}</td>
                        </tr>
                    }
                    {referencingOrderInfo &&
                        <tr>
                            <td>{getReferencingDescription(referencingOrderInfo)}</td>
                            <td><Link to={"/bestellungen/bearbeiten/"+referencingOrderInfo.order}>{referencingOrderInfo.order}</Link></td>
                        </tr>
                    }
                </tbody>
            </table>

            <OrderItems data={order}/>

            <OrderShippingAndTotal orderItems={order.items} shipping={order.shippingInfo}/>

            {order.billingAddress && <>
                <h2 className="mt-1d5 mb-03">{order.billingAddress.type === "BOTH" ? "Rechnungs- und Lieferadresse" : "Rechnungsadresse"}</h2>
                <OrderAddressDisplay address={order.billingAddress}/>

                {order.billingAddress.type !== "BOTH" &&
                    <>
                        <h2 className="mt-1d5 mb-03">Lieferadresse</h2>
                        <OrderAddressDisplay address={order.shippingAddress}/>
                    </>}
            </>}

        </>
    )
}