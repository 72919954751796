import {OrderItems} from "../../component/OrderItems";
import {DataTable} from "../../component/datatable/DataTable";
import {formatPrice, germanDate, sumItemsDouble} from "../../js/util";
import {SelectOne} from "../../component/form/SelectOne";
import {yearsOptions} from "../../js/constants";
import {BreadCrumb} from "../../component/Breadcrumb";
import {SelectReact} from "../../component/form/SelectReact";

export const getOrderStateTag = (state) => {
    const msg = getOrderStateText(state);
    switch (state) {
        case "CREATED":
            return <span className="lightgrey">{msg}</span>;
        case "OPEN":
            return <span className="red">{msg}</span>;
        case "IN_PROGRESS":
            return <span className="orange">{msg}</span>;
        case "AWAIT_PAYMENT":
            return <span className="lightgrey">{msg}</span>;
        case "CANCELED":
            return <span className="grey">{msg}</span>;
        case "COMPLETED":
            return <span className="green">{msg}</span>;
        default:
            return state;
    }
};

export const getOrderStateText = (state) => {
    switch (state) {
        case "CREATED":
            return "Zeichnung ausständig"
        case "OPEN":
            return "Offen";
        case "IN_PROGRESS":
            return "in Bearbeitung";
        case "AWAIT_PAYMENT":
            return "Bezahlung ausständig";
        case "CANCELED":
            return "Storniert";
        case "COMPLETED":
            return "Abgeschlossen";
        default:
            return state;
    }
}

export const Overview = ({orders, selectedYear, setSelectedYear}) => {

    const columns = [
        {
            name: "Bestellnummer",
            selector: (row) => row.id,
            sortable: true
        },
        {
            name: "Datum",
            selector: (row) => germanDate(row.creationDate),
            sortable: true,
            sortFunction: (first, second) => first.creationDate - second.creationDate
        },
        {
            name: "Beschreibung",
            selector: (row) => row.summary,
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => getOrderStateTag(row.state),
            sortFunction: (first, second) => getOrderStateText(first.state).localeCompare(getOrderStateText(second.state)),
            sortable: true
        },
        {
            name: "Username",
            selector: (row) => row.username,
            maxWidth: '200px',
            sortable: true,
        },
        {
            name: "Summe (netto)",
            selector: (row) => formatPrice(row.sum),
            right: true,
            sortable: true,
            maxWidth: '200px',
            sortFunction: (first, second) => sumItemsDouble(first.items) - sumItemsDouble(second.items),
        },
    ];

    return (
        <div>
            <BreadCrumb currentTitle="Meine Bestellungen"/>
            <div className="data-table">
                <DataTable data={orders}
                           columns={columns}
                           expandableRows
                           expandOnRowClicked
                           expandableRowsComponent={<OrderItems inline/>}
                           actions={<SelectReact value={selectedYear} setter={setSelectedYear} options={yearsOptions}/>}/>
            </div>

        </div>
    );

};