import {Fragment, useEffect, useState} from "react";
import {markDirty, showError, showMessage} from "../js/main";
import {handleApiError, post} from "../js/api";
import {BreadCrumb} from "./Breadcrumb";
import {OrderInformation} from "./OrderInformation";
import {InputText} from "./form/InputText";
import {Button} from "./form/Button";
import {orderType} from "../js/constants";

const getWording = (order) => {
    return order.type == orderType.request ? "Anfrage" : "Bestellung";
}

export const OrderUpdate = ({children, order, signed, canceled, tanFailed, isPublic}) => {

    const [signatureCode, setSignatureCode] = useState();

    const [submitting, setSubmitting] = useState(false);
    const [canCancel, setCanCancel] = useState(true)

    useEffect(() => {
        if(order){
            setCanCancel(["CREATED", "AWAIT_PAYMENT"].indexOf(order.state) != -1);
        }

    }, [order]); // eslint-disable-line react-hooks/exhaustive-deps

    const signOrder = () => {
        setSubmitting(true);

        const req = {
            tan: signatureCode
        };

        post((isPublic ? "/public":"") + "/orders/" + order.id + "/signOrder", req, false).then((resp) => {
            showMessage("Die "+getWording(order)+" wurde erfolgreich bestätigt.");
            signed(resp);
        }).catch(err => {
            setSignatureCode("");
            let msg = "Der eingegebene Code war nicht korrekt.";
            if (err.response.data) {
                switch (err.response.data.errorType) {
                    case "RETRY":
                        msg += "Bitte versuchen Sie es erneut.";
                        break;
                    case "FAIL":
                        msg += "Die  "+getWording(order)+" wurde storniert.";
                        tanFailed();
                        break;
                    default:
                        break;
                }
                showError(msg);
                return;
            }
            handleApiError(err);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const requestNewTan = () => {
        setSubmitting(true);
        post((isPublic ? "/public":"") +"/orders/" + order.id + "/requestNewTan").then(() => {
            showMessage("Neuer Code wurde erfolgreich versendet.");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const cancelOrder = () => {
        setSubmitting(true);
        post((isPublic ? "/public":"") +"/orders/" + order.id + "/cancelOrder").then(() => {
            markDirty();
            showMessage("Die "+getWording(order)+" wurde erfolgreich storniert.");
            canceled();
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Fragment>
            {order &&
                <div>
                    {order.state === "CREATED" &&
                        <div className="zeichnung mt-2 mb-2">
                            <h2 className="primary-link mt-0">{getWording(order)} autorisieren</h2>
                            <p>
                                <strong>Sie haben den Bestätigungscode per Mail erhalten. Überprüfen Sie Ihren Spam-Ordner!</strong> Mit Eingabe des
                                Codes wird die {getWording(order)} abgeschickt, Sie stimmen damit unseren AGB zu und es wird ein rechtsgültiges Geschäft
                                abgeschlossen. Der Code besteht nur aus Zahlen.
                            </p>
                            <form onSubmit={event => {
                                event.preventDefault();
                                signOrder();
                            }}>
                                <InputText required label="Code" name="tan" value={signatureCode} setter={newValue => setSignatureCode(newValue)}/>
                                <Button submitting={submitting} width={220} theme="green mr-1 mb-05">{getWording(order)} bestätigen</Button>
                                <Button submitting={submitting} width={230} type="button mb-05" onClick={() => requestNewTan()} theme="orange">Neuen Code anfordern</Button>
                            </form>
                        </div>}

                    {children}

                    {canCancel && <form onSubmit={e => {e.preventDefault();}} className="storno mt-2 ">
                        <h3 className="red mt-0">{getWording(order)} stornieren</h3>
                        <p>
                            Wenn Sie die {getWording(order)} stornieren, kann das nicht mehr widerrufen werden. Wenn Sie die {getWording(order)} später dennoch
                            durchführen wollen, muss eine neue {getWording(order)} erstellt werden. Nach der Zeichnung kann die {getWording(order)} nur mehr von
                            Mitarbeitern der Lintner GmbH storniert werden. Setzen Sie sich hierfür bei Bedarf mit dem Kundenservice in
                            Kontakt.
                        </p>
                        <Button submitting={submitting} width={230} theme="red" onClick={() => cancelOrder()}>{getWording(order)} stornieren</Button>
                    </form>}

                    <br/><br/>
                </div>}
        </Fragment>
    );

};