import {Layout} from "../../component/layout/Layout";
import {useEffect, useRef, useState} from "react";
import {empty as emptyAddress} from "../../component/form/AddressInput";
import {Button} from "../../component/form/Button";
import {BreadCrumb} from "../../component/Breadcrumb";
import {post} from "../../js/api";
import {showMessage} from "../../js/main";
import {OrderUpdate} from "../../component/OrderUpdate";
import {orderType} from "../../js/constants";
import {InputType} from "../../component/form/InputType";
import {deepCopy} from "../../js/util";
import {InputImageFancy} from "../../component/form/InputImageFancy";

import exampleFrontside from "../../img/example-frontside.jpg";
import exampleBackside from "../../img/example-backside.jpg";
import exampleFrontsideCard from "../../img/example-frontside-card.jpg";
import exampleBacksideCard from "../../img/example-backside-card.jpg";
import {RequestInformation} from "./RequestInformation";
import {ReactComponent as IconDelete} from "../../img/icon/delete.svg";
import {InputText} from "../../component/form/InputText";
import {InputTextarea} from "../../component/form/InputTextarea";
import {useLocation} from "react-router";


export const GuestRequest = () => {

    const location = useLocation();

    const [billingAddress, setBillingAddress] = useState(emptyAddress);
    const [info, setInfo] = useState({frontImage: undefined, backImage: undefined, authorization:undefined, keynumber: undefined, amount: undefined, additionalInfo:undefined});
    const [submittable, setSubmittable] = useState(true);
    const [orderInfo, setOrderInfo] = useState(null);
    const [done, setDone] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [hasShownBanner, setHasShownBanner] = useState(false);
    const uniqueRef = useRef(new Set());
    const [fastMode, setFastMode] = useState(false);

    const handleFile = (f, name) => {
        handleUpdate(f, name);
    }
    const handleUpdate = (newValue, field) => {
        let updated = Object.fromEntries(Object.entries(info));
        updated[field] = newValue;
        setInfo(updated)
    };
    useEffect(() => {
        if(location.search){
            var params = new URLSearchParams(location.search);
            const fastMode = params.get("fast");

            setFastMode(fastMode === 'true');
        }
    }, []);

    const [submitting, setSubmitting] = useState(false);

    const uploadImage = async (side, file) => {
        const date = new Date().getTime();
        const filename = `Fotoupload_${date}_${side}${file.name.substring(file.name.indexOf("."))}`;
        const resp = await post("/public/s3/generateUpload", {
            "folder": "orderImages",
            "fileName": filename,
            "fileType": file.type
        });
        await fetch(resp.httpUploadUri, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Disposition": "attachment; filename*= UTF-8''" + filename
            }
        });

        return resp.s3identifier;
    }
    const createOrder = async () => {
        setSubmitting(true);
        try {
            const front = await uploadImage("vorne", info.frontImage);
            const back = await uploadImage("hinten", info.backImage);
            let authorization;
            if(info.authorization){
                authorization = await uploadImage("Sicherungskarte", info.authorization);
            }


            const item = await post("/guestRequests/createNewOrderItem", {
                frontImage: front,
                backImage: back,
                authorization: authorization,
                keynumber: info.keynumber,
                amount: info.amount,
                additionalInfo: info.additionalInfo
            });

            const orderReq = {
                summary: "Fotobestellung Schlüssel für " + billingAddress.email,
                shippingInfo: undefined,
                billingAddress: billingAddress,
                paymentType: undefined,
                type: orderType.request,
                items: [item]
            };

            await post("/public/orders", orderReq).then(o => {
                showMessage("Anfrage gespeichert. Sie haben eine Mail mit dem Bestätigungscode erhalten.");
                setOrderInfo(o);
            });

        } finally {
            setSubmitting(false);
        }
    }

    const signed = () => {
        orderInfo.state = "OPEN";
        setOrderInfo(orderInfo);
        setDone(true);
        //handle payment
    }

    const showInfoOverlay = (e) => {
        if(hasShownBanner || fastMode){
            return;
        }
        e.preventDefault();
        setShowBanner(true);
        setHasShownBanner(true);
    }

    useEffect(() => {
        document.body.style.overflow = showBanner ? "hidden" : "scroll";
    }, [showBanner]);

    const doNothing = () => {
    }

    return (
        <Layout username={null}>
            <BreadCrumb currentTitle="Anfrage: Nachschlüssel"/>
            {!done && <>
                {orderInfo == null && <>
                    <form onSubmit={e => {
                        e.preventDefault();
                        createOrder();
                    }}>
                        {!fastMode && <><h2 className="mt-2 mb-05">Empfohlen: Keydirect-Scancard downloaden</h2>
                            <p>
                                Für die genauen Maße und Erkennbarkeit des Schlüssels laden Sie bitte die Scancard
                                herunter, drucken sie aus und nutzen diese als Hintergrund, um das Foto vom Schlüssel zu
                                machen.
                            </p>
                            <div className="default">
                                <div>
                                    <a href="https://key-direct-production-bucket.s3.eu-central-1.amazonaws.com/public/Schluesselskala.pdf"
                                       target="_blank">
                                        <Button type={"button"} width={300}>
                                            Download Scancard
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </>}
                        <h2 className="mt-2 mb-05">Schlüsselbilder hochladen</h2>
                        <p>
                            Einfach ein Foto machen, hochladen, Ihre Mailadresse eingeben und wir klären die
                            Details.<br/>
                            <small>Nur mit gelbem <span className="primary-link bold">*</span> markierte Felder sind
                                Pflichtfelder.</small>
                        </p>
                        <div className="default side-by-side-3">
                            <InputImageFancy label={"Vorderseite"} name={"frontImage"} maxMBSize="10"
                                             setter={handleFile} required onClick={showInfoOverlay}
                                             uniqueRef={uniqueRef}/>
                            <InputImageFancy label={"Rückseite"} name={"backImage"} maxMBSize="10"
                                             setter={handleFile} required onClick={showInfoOverlay}
                                             uniqueRef={uniqueRef}/>
                            <InputImageFancy
                                label={"Sicherungskarte / Schlüsselberechtigung"}
                                name={"authorization"} setter={handleFile} uniqueRef={uniqueRef}/>
                        </div>
                        <h4 className="mt-05 mb-04">Optionale Zusatzinfos</h4>
                        <div className="default">
                            <InputText label={"Schlüsselnummer"}
                                           name={"keynumber"} value={info.keynumber} setter={handleUpdate}/>
                            <InputType label={"Anzahl"} type={"number"}
                                           name={"amount"} value={info.amount} setter={handleUpdate}/>
                            <InputTextarea label={"Alles was wir sonst noch wissen sollten (Infofeld)"}
                                           name={"additionalInfo"} value={info.additionalInfo} setter={handleUpdate}
                                           rows={3}/>
                        </div>
                        {showBanner && <>
                            <div className={"background-blur"}></div>
                            <div className={"upload-info-banner"}>
                                <div>
                                    <div className={"abort-btn"} onClick={() => setShowBanner(false)}>
                                        Info schließen <IconDelete></IconDelete>
                                    </div>
                                    <h2>Tipps für den Bildupload mit Scancard</h2>
                                    <p>
                                        Legen Sie den Schlüssel auf die Skala der ausgedruckten Karte und machen Sie
                                        jeweils ein Bild von jeder Seite.<br/> Beispiel:
                                    </p>
                                    <div className={"example-container"}>
                                        <img src={exampleFrontsideCard}/> <img src={exampleBacksideCard}/>
                                    </div>
                                </div>
                                <div>
                                    <h2>Tipps für den Bildupload ohne Scancard</h2>
                                    <p>
                                        Wählen Sie bitte einen gleichmäßigen Hintergrund (zum Beispiel ein Blatt Papier)
                                        und legen Sie im besten Fall als
                                        Größenreferenz noch eine 2-Euro-Münze neben den Schlüssel.<br/> Beispiel:
                                    </p>
                                    <div className={"example-container"}>
                                        <img src={exampleFrontside}/> <img src={exampleBackside}/>
                                    </div>
                                </div>
                            </div>
                        </>}
                        <h2 className="mt-2 mb-05">Kontaktinformation</h2>

                        <InputType name="email" value={billingAddress.email} label="E-Mail" type="email"
                                   setter={(e) => {
                                       let ba = deepCopy(billingAddress);
                                       ba.email = e;
                                       setBillingAddress(ba);
                                   }} required={true}/>

                        <p>
                            Nach Anklicken des Buttons „Anfrage absenden“ erhalten Sie einen Verifizierungscode,
                            mit dem Sie Ihre unverbindliche Anfrage für eine Schlüsselnachbestellung bestätigen können.
                            Dies dient zur Verifikation der E-Mail Adresse.
                            Unser Team wird Ihnen dann ein Mail mit Informationen und Preis senden oder sich
                            gegebenenfalls bei Fragen bei Ihnen melden - VIELEN DANK
                        </p>
                        <div className={"safe-actions mt-1"}>
                            <Button type="button" key="back" theme="secondary-button mr-1 mb-1" width={230}
                                    submitting={submitting} onClick={e => {
                                e.preventDefault();
                                window.history.back()
                            }}>zurück</Button>
                            <Button type={"submit"} key="submit" theme="primary-button mb-1" width={230}
                                    submitting={submitting}>Anfrage absenden</Button>
                        </div>
                    </form>
                </>}

                {orderInfo != null && <>
                    <OrderUpdate order={orderInfo} signed={signed} canceled={doNothing} tanFailed={doNothing}
                                 isPublic={true}>
                        <RequestInformation order={orderInfo}/>
                    </OrderUpdate>
                </>}
            </>}


            {done && <>
                <p>
                    Vielen Dank für Ihre Anfrage! Wir werden uns umgehend bei Ihnen melden.
                </p>

                <RequestInformation order={orderInfo}/>

                <div className={"safe-actions mt-1"}>
                    <Button key="submit" theme="primary-button mb-1" width={230} onClick={e => {
                        e.preventDefault();
                        window.location.reload();
                    }} submitting={submitting}>Nächste Schlüsselbestellung</Button>
                </div>
            </>
            }

        </Layout>
    );
}