import {useEffect, useRef, useState} from "react";
import ReactTable, {createTheme} from "react-data-table-component";
import {customStyles, customTheme} from "./DataTableTheme";
import {DataTableSelectComponent} from "./DataTableSelectableComponent";
import {Button} from "../form/Button";
import {markDirty, showError, showMessage} from "../../js/main";
import {del} from "../../js/api";
import {ReactComponent as IconSwipe} from "../../img/icon/left-right.svg";
import {useHistory, useLocation} from "react-router";

// docs: https://www.npmjs.com/package/react-data-table-component/v/6.11.7

export const DataTable = ({
                              columns,
                              data,
                              actions,

                              removeTopPadding = false,

                              // only for delete or batch edit
                              nameField = 'title',
                              primaryKey = 'id',
                              // ----

                              // only for batch edit
                              batchEditUrl,
                              // ----

                              // only for deleting
                              deleteApi,
                              currentUrl, // where to go after delete
                              preventDeletingAll = false,
                              // ----

                              noSearch = false,
                              paginationPerPage = 10,
                              noScrollInfo = false,
                              ...props
                          }) => {

    const history = useHistory();
    const location = useLocation();

    const [clearSelected, setClearSelected] = useState(false);
    const [filterString, setFilterString] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [timeoutHandle, setTimeoutHandle] = useState();

    const handleSearchParam = () => {
        if(location.search){
            var params = new URLSearchParams(location.search);
            const filter = params.get("filter");

            if(filter){
                if(filter != filterString) {
                    handleFilterInput(filter);
                }
                return;
            }
        }
        if(filterString != ""){
            handleFilterInput("");
        }
    }

    useEffect(() => {
        if(!noSearch){
            handleSearchParam();
        }
    }, [location.search]);

    const handleFilterInput = (newValue) => {
        setFilterString(newValue);
        if (timeoutHandle) {
            clearTimeout(timeoutHandle);
        }
        setTimeoutHandle(setTimeout(() => {
            setFilteredData(filterData(newValue)); // as paramater because otherwise filterData method accesses
            // per closure the old state, setFilterString doesn't update state instantly
            history.push({pathname: location.pathname, search: "filter="+encodeURIComponent(newValue)});
        }, 300));
    };

    const filterData = (needle) => {
        if (needle && data) {
            const matchingData = [];
            for (const elem of data) {
                for (const item of columns) {
                    const selector = item.selector;
                    if (selector) {
                        let content = selector(elem);
                        if (typeof content === 'object' && content.props && content.props['children']) {
                            content = content.props['children'];
                        }
                        if (String(content).toLowerCase().includes(needle.toLowerCase())) {
                            matchingData.push(elem);
                            break;
                        }
                    }
                }
            }

            return matchingData;
        } else {
            return data || [];
        }
    };

    useEffect(() => {
        setFilteredData(filterData(filterString));
    }, [columns, data]); // eslint-disable-line react-hooks/exhaustive-deps


    const [state, setState] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);

    const handleBatch = () => {
        setSubmitting(true);
        const ids = state.selectedRows.map(shipping => shipping[primaryKey]);
        history.push(batchEditUrl + "/" + ids.join(","));
        setSubmitting(false);
    };

    const handleDelete = () => {
        if (!window.confirm(state.selectedRows.length === 1
                            ? `"${state.selectedRows[0][nameField]}" löschen?`
                            : `${state.selectedRows.length} Einträge löschen?`)) {
            return;
        }

       if (preventDeletingAll && state.selectedRows.length === data.length) {
           showError("Es muss mindestens eine Option übrig bleiben.");
           return;
       }

        setSubmitting(true);
        const ids = state.selectedRows.map(shipping => shipping[primaryKey]);

        const expectedRequests = ids.length;
        let successfullReq = 0;
        const finalAction = () => {
            markDirty();
            showMessage("Löschen erfolgreich");
            setClearSelected(!clearSelected);
            setSubmitting(false);
            history.push(currentUrl);
        }

        // maybe not ideal, but async and fast anyway, so who cares =)
        // it also has an advantage: it is not "transactional", so if one delete fails, the others would still complete
        ids.forEach(id => {
            del(deleteApi + id).then(() => {
                successfullReq++;

                const row = document.getElementById("row-" + id);
                if (!row) {
                    if (successfullReq === expectedRequests) {
                        finalAction();
                    }
                    return;
                }
                row.style.opacity = "1";
                row.style.transition = "1.5s opacity";
                row.style.opacity = "0";
                setTimeout(() => {
                    row.style.display = "none";
                    if (successfullReq === expectedRequests) {
                        finalAction();
                    }
                }, 2500);
            }).catch(() => {
                setClearSelected(!clearSelected);
                setSubmitting(false);
            });
        });
    };

    createTheme("keydirect", customTheme);

    const hide= e => e.target.style.display = "none";
    const scrollInfoRef = useRef()
    useEffect(() =>{
        if(noScrollInfo){
            return;
        }
        if(scrollInfoRef.current.parentNode.parentNode.getElementsByClassName("rdt_Table")[0].clientWidth <= window.innerWidth){
            scrollInfoRef.current.style.display="none";
        }else{
            scrollInfoRef.current.style.display="flex";
        }
    } ,[filteredData])

    return (
        <div className="data-table">
            {!noScrollInfo && <div className="data-table-scroll-info" onTouchStart={hide} onClick={hide} ref={scrollInfoRef}>
                    <IconSwipe/>
                    <p>Nach links und rechts scrollen</p>
            </div>}
            <div className={"data-table-topbar " + (removeTopPadding ? '' : 'mb-1')}>
                {data !== null && data.length > 0 && !noSearch &&
                 <div>
                     <input type="text"
                            value={filterString}
                            onChange={e => handleFilterInput(e.target.value)}
                            name="filterInput"
                            placeholder="Suche"
                            aria-label="Suche"/>
                 </div>}

                {batchEditUrl && state && state.selectedRows.length > 0 &&
                <Button type="button" theme="green" width={220} onClick={handleBatch} submitting={isSubmitting}>
                    {state.selectedRows.length} Einträge bearbeiten
                </Button>}

                {deleteApi && state && state.selectedRows.length > 0 &&
                 <Button type="button" theme="red" width={200} onClick={handleDelete} submitting={isSubmitting}>
                     {state.selectedRows.length} Einträge löschen
                 </Button>}
                {actions}

            </div>

            <ReactTable noHeader
                        columns={columns}
                        data={filteredData}
                        pointerOnHover={!!props.onRowClicked}
                        pagination={data && data.length > 10}
                        paginationPerPage={paginationPerPage}
                        paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                        selectableRowsHighlight
                        selectableRowsComponent={DataTableSelectComponent}
                        selectableRowsComponentProps={{isIntermediate: state && state.selectedCount > 0 && !state.allSelected}}
                        onSelectedRowsChange={s => setState(s)}
                        clearSelectedRows={clearSelected}
                        paginationComponentOptions={{rowsPerPageText: 'Einträge pro Seite', rangeSeparatorText: 'von'}}
                        progressPending={data === null}
                        progressComponent={<div style={{fontSize: "24px", padding: "24px"}} className="bold">Laden...</div>}
                        theme="keydirect"
                        customStyles={customStyles}
                        noDataComponent="Keine Daten vorhanden."
                        {...props}/>
        </div>
    );

};