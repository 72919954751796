/*
 *  ~~~~~ Copyright by inndevs GmbH / DDC Engineering GmbH ~~~~~
 *
 *               Your favorite programmers ;-)
 *
 * E-Mail:   hannes@inndevs.com
 *           dominic@ddc-engineering.at
 * Web:      https://inndevs.com
 *           https://ddc-engineering.at
 * Date:     2021-05-16
 *
 *   ________________________________________
 * [[ MESS WITH THE BEST - DiE LIKE THE REST ]]
 *   ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
 */


import {Fragment, useEffect, useState} from "react"
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
import Bus from "./js/Bus";
import {Login} from "./component/layout/Login";
import {KeyDirect} from "./component/layout/KeyDirect";
import {Route, Switch} from "react-router";
import {Payment} from "./component/layout/Payment";
import {Impressum} from "./view/home/Impressum";
import {Layout} from "./component/layout/Layout";
import {GuestRequest} from "./view/guestRequest/GuestRequest";
import {QrOrder} from "./controller/QrOrder";
import {AGB} from "./view/home/AGB";
import {Datenschutz} from "./view/home/Datenschutz";
import {Widerrufsrecht} from "./view/home/Widerrufsrecht";
import {useHistory} from "react-router-dom";
import {post} from "./js/api";


function App() {

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [currentVersion, setCurrentVersion] = useState();
    const history = useHistory();

    const handleStateUpdate = (state) => {
        switch (state) {
            case AuthState.SignedIn:
                setLoggedIn(true);
                break;
            default:
                setLoggedIn(false);
        }
    };

    useEffect(() => {
        //make dark theme standard
        document.querySelector(":root").classList.add("dark-theme");

        window.onerror = function(msg, url, line, col, error) {
            var error_data = {
                url: document.location.href,
            };

            if(error != null) {
                error_data['name'] = error.name; // e.g. ReferenceError
                error_data['message'] = error.line;
                error_data['stack'] = error.stack;
            } else {
                error_data['message'] = msg;
                error_data['filename'] = url;
                error_data['line'] = line;
                error_data['col'] = col;
            }

            post('/logs/logerror', error_data).then(() => {
                console.log("Successfully logged error");
            });
        }

        onAuthUIStateChange(handleStateUpdate)
        Bus.removeAllListeners("loginstate");
        Bus.addListener("loginstate", handleStateUpdate);

        loadVersion();

        // redirect upload. domain to guest-request
        if(window.location.host.indexOf("upload") > -1 && window.location.pathname == "/"){
            history.push("/guest-request")
        }
    }, []);

    useEffect(() => {
        //every hour check for a new version (long open tab, I don't want to go chasing bugs)
        const interval = setInterval(loadVersion, 60*60*1000);

        return () => clearInterval(interval)
    }, [currentVersion]);

    const loadVersion = () => {
        fetch("/version.json").then((res) => {
            if (!res.ok) {
                throw new Error
                (`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        }).then(res => {
                if(currentVersion && currentVersion != res.version){
                    if(window.confirm("Neue Version verfügbar, bitte laden Sie die Seite neu. Drücken Sie dafür auf OK.")) {
                        window.location.reload();
                    }else{
                        return;
                    }
                }
                setCurrentVersion(res.version);
        }).catch((error) =>
                console.error("Unable to fetch data:", error));
    }

    return (
        <Fragment>
            <Switch>
                <Route exact path="/payments/:paymentId/:done?">
                    <Payment />
                </Route>
                <Route path="/impressum">
                    <Layout>
                        <Impressum/>
                    </Layout>
                </Route>
                <Route path="/agb">
                    <Layout>
                        <AGB/>
                    </Layout>
                </Route>
                <Route path="/datenschutz">
                    <Layout>
                        <Datenschutz/>
                    </Layout>
                </Route>
                <Route path="/widerrufsrecht">
                    <Layout>
                        <Widerrufsrecht/>
                    </Layout>
                </Route>
                <Route path="/guest-request">
                    <GuestRequest/>
                </Route>
                <Route path="/qr/:qr">
                    <QrOrder/>
                </Route>
                <Route path="*">
                {!isLoggedIn && <Login handleStateUpdate={handleStateUpdate}/>}
                {isLoggedIn && <KeyDirect/>}
                </Route>
            </Switch>
        </Fragment>
    );

}

export default App;
